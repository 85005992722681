import { child, get, set } from "@firebase/database";
import { getDatabaseReference } from "../../firebase";
import { urls } from "../../urls";

export interface IAnnoucmentSchema{
    Annoucment: Array<IAnnoucment>;
};

export interface IAnnoucment{
    Id: number;
    Content: string;
};

const useAnnoucmentService = () => {
    const dbRef = getDatabaseReference(urls.Annoucment.Index);

    const getAnnoucments = async() : Promise<Array<IAnnoucment>> => {
        const snapshot = await get(child(dbRef, '/'));
        const result = snapshot.val() as Array<IAnnoucment>;
        result.sort((a, b) => a.Id - b.Id);
        return result;
    };

    const addAnnoucments = async(model: IAnnoucmentSchema) => {
        await set(dbRef, model.Annoucment);
    };

    const validateAnnoucmentJson = (model: IAnnoucmentSchema) : boolean => {
        try{
            model.Annoucment.forEach(annoucment => {
                if(annoucment.Content === undefined || annoucment.Id === undefined){
                    throw new Error("Chybí některá z požadovaných hodnot");
                };
            });
            return true;
        }catch(e: any){
            window.alert("Soubor JSON není ve správném formátu.\n" +
                "Stáhněte si ukázkový JSON soubor z odkazu v  nápovědě.\n" +
                `Chybová hláška: ${e.message}`);
            return false;
        }
    }

    return { getAnnoucments, addAnnoucments, validateAnnoucmentJson };
};

export { useAnnoucmentService };