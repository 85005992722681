import React from "react"
import { IAnnoucment, useAnnoucmentService } from "../../dataServices/annoucment/annoucmentService";
import { DataFetcher } from "../../dataServices/core/DataFetcher";

const Annoucments : React.FunctionComponent = () => {
    const annoucmentDataService = useAnnoucmentService();

    return (
        <>
            <h3>Aktuality</h3>
            <DataFetcher dataMethod={annoucmentDataService.getAnnoucments}>
                {(data: Array<IAnnoucment>) => {
                    return <ul>
                        {data.sort(i => i.Id).map((item, index) => (
                            <li dangerouslySetInnerHTML={{__html: item.Content}} key={index} />
                        ))}
                    </ul>
                    }
                }
            </DataFetcher>
        </>
    )
}

export default Annoucments;