import { FunctionComponent } from "react";

interface IProps{}

const ParishHistory : FunctionComponent<IProps> = () => {
    return (
        <>
            <h3>Historie farnosti</h3>
            <table className="table">
                <tbody>
                    <tr>
                        <td>1197 – 1222:</td>
                        <td>První písemné zmínky o Hostěradicích. Obec patří pod správu louckého kláštera.</td>
                    </tr>
                    <tr>
                        <td>1237:</td>
                        <td>
                            Část Hostěradic dostal Řád německých rytířů od krále Václava I. 
                            V této době je již v obci farnost, do které spadají vedle Hostěradic také Míšovice, Chlupice a Skalice. 
                            Němečtí rytíři byli v obci již před tímto rokem (zřejmě od roku 1201).
                        </td>
                    </tr>
                    <tr>
                        <td>13. století:</td>
                        <td>Stavba kaple a započetí stavby špitálu, komendy a kostela.</td>
                    </tr>
                    <tr>
                        <td>1382:</td>
                        <td>Komenda (obydlí a fara) Řádu německých rytířů patří k významným na Moravě.</td>
                    </tr>
                    <tr>
                        <td>1421:</td>
                        <td>Započata stavba věže.</td>
                    </tr>
                    <tr>
                        <td>1486:</td>
                        <td>
                            Konec působení Řádu německých rytířů v obci jako jeden z důsledků poražení řádu v bitvě u Grunwaldu (1410).
                             Za dobu působení rytíři spravovali farnost a působili jako faráři.
                        </td>
                    </tr>
                    <tr>
                        <td>Přelom 15. a 16. století:</td>
                        <td>
                            Přestavba kostela sv. Kunhuty v pozdně gotickém slohu.
                             Strop byl zaklenut a jižní boční loď byla zaklenuta v dvoulodí. Byla také rozšířena fara.
                        </td>
                    </tr>
                    <tr>
                        <td>1606:</td>
                        <td>Ve věži umístěn nový zvon s reliéfy Kristova života.</td>
                    </tr>
                    <tr>
                        <td>Srpen 1619:</td>
                        <td>
                            Období třicetileté války; obec přepadena jízdními žoldnéři, kteří vykradli kostel a zapálili obec.
                             Špitál, škola, kostel i fara vyhořely.
                        </td>
                    </tr>
                    <tr>
                        <td>1634:</td>
                        <td>Kostel za neznámých okolností vyhořel.</td>
                    </tr>
                    <tr>
                        <td>1673:</td>
                        <td>Hostěradická farnost povýšena na děkanství.</td>
                    </tr>
                    <tr>
                        <td>1728:</td>
                        <td>Postaven <a href="/MariaPillar">Mariánský sloup na náměstí.</a></td>
                    </tr>
                    <tr>
                        <td>1806:</td>
                        <td>Postavena nová školní budova (zdemolována r. 1994)</td>
                    </tr>
                    <tr>
                        <td>Konec 18. století:</td>
                        <td>Zrušen hřbitov u kostela a založen nový (současný)</td>
                    </tr>
                    <tr>
                        <td>1787:</td>
                        <td>Postavena <a href="/MisoviceChapel">míšovická kaple Nanebevzetí Panny Marie</a></td>
                    </tr>
                    <tr>
                        <td>1925:</td>
                        <td>
                            Ve věži byly umístěny tři nové zvony jako náhrada za zvony zabavené v průběhu první světové války.
                             Na věži tak byl historicky nejstarší zvon z roku 1606, a k němu přibyly zvony Srdce Ježíšovo, Marie a Anna.
                        </td>
                    </tr>
                    <tr>
                        <td>28. 5. 1928:</td>
                        <td>Zásah blesku rozbil věžní jehlan.</td>
                    </tr>
                    <tr>
                        <td>1975:</td>
                        <td>Oprava věžního jehlanu (preventivní)</td>
                    </tr>
                    <tr>
                        <td>70. a 80. léta 20. století:</td>
                        <td>
                            Kompletní oprava celého kostela díky práci P. Františka Cahy.
                             Provedeny opravy zdiva, vyměněna dlažba, kostel rozdělen na letní a zimní část.
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default ParishHistory;