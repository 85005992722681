import { useState } from "react";
import { ItemSort } from "../../../components/itemSort/ItemSort";
import { DataFetcher } from "../../../dataServices/core/DataFetcher";
import { IDocument, useDocumentService } from "../../../dataServices/document/documentService";
import {v4 as uuid} from 'uuid';

const DocumentManagement : React.FunctionComponent = () => {
    const documentDataService = useDocumentService();
    const [isHintVisible, setIsHintVisible] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [dataLoadTrigger, setDataLoadTrigger] = useState(uuid());

    const onActualizeDocumentsClick = async () => {
        setIsInProgress(true);
        await documentDataService.actualizeDocuments()
            .then(_ => 
                {
                    setIsInProgress(false);
                    setDataLoadTrigger(uuid());
                });
    }
    
    return (
        <>
            <h2>Správa dokumentů ke stažení</h2>
            <div className="row">
                <span className="link mb-2" onClick={() => setIsHintVisible(!isHintVisible)}>
                    {isHintVisible ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
                </span>
            </div>
            {isHintVisible && (
                <div className="row">
                    <div className="col-12">
                        <p>
                            Dokumenty se nacházejí ve složce Document (viz první nápovědu). 
                            Po nahrání souborů přejděte zpět na tuto stránku
                            a stiskněte tlačítko <strong>Aktualizovat dokumenty ke stažení</strong> níže.
                        </p>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-12">
                    <button type="button" disabled={isInProgress} onClick={onActualizeDocumentsClick}>Aktualizovat dokumenty ke stažení</button>
                </div>
            </div>
            <DataFetcher dataMethod={documentDataService.getDocuments} triggerFetchProperty={dataLoadTrigger}>
                {(documents: Array<IDocument>) => <ItemSort data={documents} onSubmit={documentDataService.setDocuments} />}
            </DataFetcher>
        </>
    )
};

export { DocumentManagement };