import { DataFetcher } from "../../dataServices/core/DataFetcher";
import { IDocument, useDocumentService } from "../../dataServices/document/documentService";

const Documents : React.FunctionComponent = () => {
    const documentsDataService = useDocumentService();

    return (
        <>
            <h3>Dokumenty ke stažení</h3>
            <DataFetcher dataMethod={documentsDataService.getDocuments}>
                {(data: Array<IDocument>) => (
                    <ul>
                    {data.map((document, index) => 
                        <li key={index}>
                            <a href={document.DocumentUrl} target='_blank' rel='noreferrer'>{document.Label}</a>
                        </li>
                        )}
                    </ul>
                )}
            </DataFetcher>
        </>
    )
}

export default Documents;