import React from "react";
import { Route } from "react-router-dom";
import { AuthRouteInterceptor } from "./AuthRouteInterceptor";

interface  IPrivateRouteProps {
    path: string;
    component: React.ComponentType<any>;
    exact?: boolean;
};

const PrivateRoute : React.FunctionComponent<IPrivateRouteProps> = (props) => {
    return (
        <Route
            exact={props.exact}
            path={props.path}
            render={(routeProps) => (
                <AuthRouteInterceptor>
                    <props.component {...routeProps}/>
                </AuthRouteInterceptor>
            )}/>
    );
};

export default PrivateRoute;