const urls = {
    Annoucment: {
        Index: 'Annoucment',
    },
    Document: {
        Index: 'Document',
    },
    Gallery: {
        Index: 'Gallery',
        FolderInfo: 'Gallery/FolderInfo',
        FolderDetail: 'Gallery/FolderDetail',
    },
    Link: {
        Index: 'Link',
    },
    MassDay: {
        Index: 'MassDay',
    },
}

export {urls};