import { IImageMetadata } from "../../components/Carousel";

const imagePaths = () => {
    const getHomePagePhotos = () : Array<IImageMetadata> => {
        return [
            {
                imagePathFHD: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FHosteradiceFHD.jpg?alt=media&token=73548150-a6fd-4a48-abab-a2f0afe404b0',
                imagePath4K: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FHosteradice4K.jpg?alt=media&token=82be4166-52f3-4c47-bfa0-49cbf081e64f',
                imagePathMobile: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FHosteradiceMobile.jpg?alt=media&token=fee18459-bbad-4d68-82d6-41df59fb139a',
                altInfo: 'Kostel sv. Kunhuty v Hostěradicích',
                description: 'Kostel sv. Kunhuty v Hostěradicích',
                title: 'Římskokatolická farnost Hostěradice'
            },
            {
                imagePathFHD: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FSkaliceFHD.jpg?alt=media&token=c22aab31-2521-4162-b58f-e7243f10f291',
                imagePath4K: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FSkalice4K.jpg?alt=media&token=16edc37b-1488-47e4-9bb9-f69b13c21b42',
                imagePathMobile: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FSkaliceMobile.jpg?alt=media&token=a5e41a78-1ea2-4636-bd8e-cce6752e6d81',
                altInfo: 'Kaple sv. Víta ve Skalici',
                description: 'Kaple sv. Víta ve Skalici',
                title: 'Římskokatolická farnost Hostěradice'
            },
            {
                imagePathFHD: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FTrsteniceFHD1.jpg?alt=media&token=e88c78eb-69f2-4635-a8f1-92d6aac13d4a',
                imagePath4K: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FTrstenice4K1.jpg?alt=media&token=43ce30b7-249a-4fce-ace8-8b39b377d4d6',
                imagePathMobile: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FTrsteniceMobile.jpg?alt=media&token=5ec9949f-0526-4e27-acc7-eb637b351f99',
                altInfo: 'Kostel Povýšení sv. Kříže v Trstěnicích',
                description: 'Kostel Povýšení sv. Kříže v Trstěnicích',
                title: 'Římskokatolická farnost Trstěnice',
            },
            {
                imagePathFHD: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FMorasiceFHD.jpg?alt=media&token=ba888e3c-5dd2-4df4-9282-618de0967761',
                imagePath4K: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FMorasice4K.jpg?alt=media&token=0670fb47-e3ae-4b87-bf9f-1d51109ca5c0',
                imagePathMobile: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FMorasiceMobile.jpg?alt=media&token=4e97e585-35f1-4886-969d-ffcaa811605e',
                altInfo: 'Kostel sv. Anny v Morašicích',
                description: 'Kostel sv. Anny v Morašicích',
                title: 'Římskokatolická farnost Trstěnice'
            },
        ];
    };

    return { getHomePagePhotos };
};

export { imagePaths };