import { child, get, set } from "@firebase/database";
import { getDownloadURL, listAll } from "@firebase/storage";
import { IOrderable } from "../../core/interfaces/IOrderable";
import { getDatabaseReference, getStorage } from "../../firebase";
import { useFileHelper } from "../../helpers/fileHelper";
import { urls } from "../../urls";

export interface IDocument extends IOrderable{
    Label: string;
    DocumentUrl: string;
}

const useDocumentService = () => {
    const dbRef = getDatabaseReference(urls.Document.Index);
    const { getFileLabel } = useFileHelper();

    const getDocuments = async() : Promise<Array<IDocument>> => {
        const result = await get(child(dbRef, '/'));
        return result.val();
    };

    const setDocuments = async(documents: Array<IDocument>) => {
        await set(dbRef, documents);
    };

    const actualizeDocuments = async() : Promise<void> => {
        const documents : Array<IDocument> = [];
        const storage = getStorage(urls.Document.Index);
        const result = await listAll(storage);
        await Promise.all(result.items.map(async(item, index) => {
            documents.push({
                Index: index,
                DocumentUrl: await getDownloadURL(item),
                Label: getFileLabel(item.name),
            });
        }));
        await setDocuments(documents);
    };

    return { getDocuments, setDocuments, actualizeDocuments };
};

export { useDocumentService };