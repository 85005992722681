import { child, DataSnapshot, get, set } from "@firebase/database";
import { IOrderable } from "../../core/interfaces/IOrderable";
import { getDatabaseReference } from "../../firebase";
import { urls } from "../../urls";

export interface IGalleryFolderInfo extends IOrderable {
    Id: string;
    Index: number;
    Label: string;
    DateCreated: string;
}

export interface IGalleryFolderItem {
    Name: string;
    Url: string;
}

export interface IGalleryFolder {
    FolderName: string;
    Items: Array<IGalleryFolderItem>;
}

const useGalleryService = () => {

    const getFolderInfo = async() : Promise<Array<IGalleryFolderInfo>> => {
        const result : Array<IGalleryFolderInfo> = [];
        const dbRef = getDatabaseReference(urls.Gallery.FolderInfo);
        const snapshot = await get(child(dbRef, '/'));
        snapshot.forEach(s => {result.push(mapFolderInfo(s))});
        return result;
    };

    const getGalleryDetail = async(id: string) : Promise<IGalleryFolder> => {
        const dbRef = getDatabaseReference(urls.Gallery.FolderDetail);
        const result = await get(child(dbRef, `/${id}`));
        return result.val();
    }

    const mapFolderInfo = (snapshot: DataSnapshot) : IGalleryFolderInfo => {
        const snapshotValue = snapshot.val() as IGalleryFolderInfo;
        return {
            Id: snapshotValue.Id,
            DateCreated: snapshotValue.DateCreated,
            Label: snapshotValue.Label,
            Index: snapshotValue.Index,
        };
    }

    const setGalleryInfo = async (galleryInfo: Array<IGalleryFolderInfo>) : Promise<void> => {
        const dbRef = getDatabaseReference(urls.Gallery.FolderInfo);
        await set(dbRef, galleryInfo);
    }

    return { getFolderInfo, getGalleryDetail, setGalleryInfo };
};

export { useGalleryService  };