import { FunctionComponent } from "react";

interface IProps{}

const SkaliceChapel : FunctionComponent<IProps> = () => {
    return (
        <>
            <h3>Kaple Skalice</h3>
            <p className="text-justify">
                Ve farnosti je více sakrálních objektů. Mezi nejvýznamnější patří <a href="/MisoviceChapel">kaple v Míšovicích </a>
                 (část Hostěradic) a kaple ve vedlejší obci Skalice.
            </p>
            <p className="text-justify">
                Kaple ve Skalici je zasvěcena svatému Vítu. Nachází se v areálu skalického zámku, který dnes slouží jako domov důchodců.
                 Konají se zde pravidelné bohoslužby, obvykle v sobotu večer, jindy podle ohlášení.
            </p>
            <div className="row">
                <div className="col-lg-2 col-sm-1"/>
                <div className="col">
                    <img src='https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FSkaliceFHD.jpg?alt=media&token=c22aab31-2521-4162-b58f-e7243f10f291' 
                    alt='Kaple Skalice'
                    className='d-block w-100'
                    style={{border: '1px solid rgb(150, 150, 150)'}}/>
                </div>
                <div className="col-lg-2 col-sm-1"/>
            </div>
        </>
    )
}

export default SkaliceChapel;