import { useState } from "react";
import { IAnnoucmentSchema, useAnnoucmentService } from "../../../dataServices/annoucment/annoucmentService";
import { useFileHelper } from "../../../helpers/fileHelper";
import { JsonSchemaHint } from "../../Shared/JsonSchemaHint";

const AnnoucmentManagement : React.FunctionComponent = () => {
    const [model, setModel] = useState<IAnnoucmentSchema>();
    const fileHelper = useFileHelper();
    const annoucmentService = useAnnoucmentService();

    const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        fileHelper.getJsonFile(event, setModel);
    };

    const handleOnUploadAnnoucmentClick = async () => {
        if(model && annoucmentService.validateAnnoucmentJson(model) === true){
            await annoucmentService.addAnnoucments(model)
                .then(_ => 
                    {
                        window.alert('Nahrávání dokončeno!')
                        setModel(undefined);
                    });
        }
    };
    
    return (
        <>
            <h2>Správa ohlášek a oznámení</h2>
            <JsonSchemaHint
                downloadFileName='AnnoucmentSchema.json'
                jsonSchemaUrl='https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/JsonSchemaExamples%2FannoucmentSchema.json?alt=media&token=63ea0563-b89a-43e3-89f5-95f61b9a5400'
            />
            <input type='file' onChange={onFileSelected}/>
            <button onClick={handleOnUploadAnnoucmentClick} disabled={!model}>Nahrát aktuality ze souboru</button>
        </>
    );
};

export { AnnoucmentManagement };