import { useContext } from "react"
import { Redirect } from "react-router-dom";
import { AuthContext } from "./AuthContext"

const AuthRouteInterceptor : React.FunctionComponent = (props) => {
    const { isAdminLoggedIn } = useContext(AuthContext);

    return (
        <>
        {isAdminLoggedIn ? (
            props.children
        ) : (
            <Redirect to={{
                pathname: '/Login',
                search: `?redirectUri=${encodeURIComponent(window.location.pathname)}`
            }}/>
        )}
        </>
    );
};

export { AuthRouteInterceptor };