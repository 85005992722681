import { FunctionComponent } from "react";

const PageWrapper : FunctionComponent = (props) => {
    return (
        <div className="container border shadow p-3 mb-5 content-card-background rounded">
            {props.children}
        </div>
    )
}

export default PageWrapper;