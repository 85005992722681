interface IProps {
    url: string;
    name: string;
    openInNewTab?: boolean;
};

const Link : React.FunctionComponent<IProps> = (props) => {
    return (
        <a 
            className="list-group-item list-group-item-action" 
            href={props.url} 
            target={props.openInNewTab === false ? '' : "_blank"} 
            rel='noreferrer'>{props.name}
        </a>
    );
};

export default Link;