import React from "react";
import queryString from 'query-string';
import { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { AuthContext } from "../core/AuthContext";

const Login : React.FunctionComponent<RouteComponentProps> = (props) => {
    const { isAdminLoggedIn, isAuthenticating, login, logout } = React.useContext(AuthContext);
    const history = useHistory();
    const { redirectUri } = queryString.parse(props.location.search);

    useEffect(() => {
        if (isAdminLoggedIn) {
            history.push(`${redirectUri}`);
        }
    }, [isAdminLoggedIn, history, redirectUri]);

    return (
        <>
        {isAuthenticating ? <div>...probíhá autentizace</div> :
            (
            <>{ isAdminLoggedIn && (
                <>
                    <h2>Přihlášen</h2>
                    <button onClick={logout}>Odhlasit se</button>
                    </>
                )}
                {!isAdminLoggedIn && (
                    <>
                    <h2>Odhlášen</h2>
                    <button onClick={login}>Přihlásit se</button>
                    </>
                )}
            </>
            )}
        </>
    );
};

export { Login };