import { FunctionComponent } from "react";

interface IProps{}

const MariaPillar : FunctionComponent<IProps> = () => {
    return (
        <>
            <h3>Mariánský sloup</h3>
            <p className="text-justify">
                Vedle farního kostela svaté Kunhuty tvoří dominantu hostěradického náměstí Mariánský sloup.
                 Byl postaven roku 1728 Štěpánem Paganem. Na vrcholu sloupu stojí na půlměsíci Panna Maria Vítězná.
                  Kolem sloupu je zábradlí, v jehož rozích jsou sochy světců sv. Josefa, Jáchyma, Šebestiána a Urbana.
            </p>
            <p className="text-justify">
                Sv. Josef byl pěstounem Ježísovým a zmiňují se o něm evangelia sv. Matouše a Lukáše.
                 Byl tesařem v Nazaretě a pocházel z rodu Davidova. Jiné věrohodné zprávy nejsou k dispozici;
                  existují však legendy a apokryfní evangelia, které ale nelze brát vážně. V každém případě sehrál
                   důležitou úlohu v životě svaté rodiny, o kterou se staral a chránil ji. Svátek sv. Josefa se slaví
                    19. března. Je patronem stolařů, řezbářů, tesařů.
            </p>
            <p className="text-justify">
                Ještě méně se toho ví o sv. Jáchymovi, o kterém se nezmiňuje žádné z evangelií. Podle starobylé tradice,
                 sahající do 2. století, byl sv. Jáchym otcem Panny Marie a manželem sv. Anny. Památka sv. Jáchyma a Anny se slaví 26. července.
            </p>
            <p className="text-justify">
                Sv. Šebestián (Sebastian) se narodil v Miláně a stal vojenským velitelem. Protože měl přístup do vězení,
                 pomáhal uvězněným křesťanům a utěšoval je v jejich těžké situaci. V roce 288 zemřel v Římě jako mučedník
                  v době pronásledování křesťanů za císaře Diokleciána. Ten poručil Šebestiány přivázat na poli ke stromu
                   a vojáci projíždějící na koních po něm stříleli jako do živého terče. Takto je po celá staletí také zobrazován.
            </p>
            <p className="text-justify">
                Sv. Urban byl papežem v letech 222 až 230, také v době pronásledování křesťanů. Snažil se pomáhat uvězněným
                 jak duchovně, tak i konáním sbírek. Podle starobylé legendy byl za císaře Alexandra Severa chycen a sťat.
                  V devátém století byly jeho ostatky přeneseny do chrámu sv. Cecílie. Sv. Urban je patronem vinařů.
            </p>
            <div className="row">
                <div className="col-lg-4 col-sm-1"/>
                <div className="col">
                    <img src='https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FSloupFHD.jpg?alt=media&token=1263fe74-c817-4c98-9dd9-fb148846bd42' 
                    alt='Mariánský sloup'
                    className='d-block w-100'
                    style={{border: '1px solid rgb(150, 150, 150)'}}/>
                </div>
                <div className="col-lg-4 col-sm-1"/>
            </div>
        </>
    )
}

export default MariaPillar;