import { ReactNode } from "react";

export interface ITabContent {
    tabTitle: string;
    tabKey: string;
    tabContent: ReactNode;
}

interface IProps {
    tabContents: Array<ITabContent>;
}

const TabPanel : React.FunctionComponent<IProps> = (props) => {
    return (
        <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {props.tabContents.map((tabData, index) => (
                    <li key={index} className="nav-item" role="presentation">
                        <button 
                            className={`nav-link ${index === 0 && 'active'}`}
                            id={`${tabData.tabKey}-tab`} 
                            data-bs-toggle="tab" 
                            data-bs-target={`#${tabData.tabKey}`}
                            type="button" 
                            role="tab" 
                            aria-controls={tabData.tabKey}
                            aria-selected="true">{tabData.tabTitle}
                        </button>
                    </li>
                ))}
            </ul>
            <div className="tab-content" id="myTabContent">
                {props.tabContents.map((tabData, index) => (
                    <div key={index} className={`tab-pane fade ${index === 0 && 'show active'}`} id={tabData.tabKey} role="tabpanel" aria-labelledby={`${tabData.tabKey}-tab`}>{tabData.tabContent}</div>    
                ))}
            </div>
        </>
    );
};

export { TabPanel };