import { useState } from "react";
import { DataFetcher } from "../../../dataServices/core/DataFetcher";
import { useGallery } from "../core/useGallery";
import { IGalleryFolderInfo, useGalleryService } from "../../../dataServices/gallery/galleryService";
import { ItemSort } from "../../../components/itemSort/ItemSort";
import {v4 as uuid} from 'uuid';

const GalleryManagement : React.FunctionComponent = () => {
    const gallery = useGallery();
    const galleryService = useGalleryService();
    const [isHintVisible, setIsHintVisible] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [dataLoadTrigger, setDataLoadTrigger] = useState(uuid());

    const regenerateGallery = async () => {
        setIsInProgress(true);
        await gallery.regenerateWholeGallery()
            .then(_ => {
                setIsInProgress(false);
                setDataLoadTrigger(uuid());
            });
    };

    return (
        <>
            <h2>Správa galerie</h2>
            <div className="row">
                <span className="link mb-2" onClick={() => setIsHintVisible(!isHintVisible)}>
                    {isHintVisible ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
                </span>
            </div>
            {isHintVisible && (
                <div className="row">
                    <div className="col-12">
                        <p>
                            Fotky se nacházejí ve složce Gallery (viz první nápovědu). 
                            Po vytvoření nové složky (například Noc Kostelů 2022) a nahrání fotek přejděte zpět na tuto stránku
                            a stiskněte tlačítko <strong>Aktualizovat galerii</strong> níže.
                        </p>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-12">
                    <button disabled={isInProgress} onClick={regenerateGallery}>Aktualizovat galerii</button>
                </div>
            </div>
            <DataFetcher dataMethod={galleryService.getFolderInfo} triggerFetchProperty={dataLoadTrigger}>
                {(galleryFolderInfo: Array<IGalleryFolderInfo>) => <ItemSort data={galleryFolderInfo} onSubmit={galleryService.setGalleryInfo}/>}
            </DataFetcher>
        </>
    );
};

export { GalleryManagement };