import { FunctionComponent } from "react";

interface IProps{
    link: string;
    text: string;
}

const NavbarItem : FunctionComponent<IProps> = (props) => {
    return (
        <li className="nav-item">
            <a className="nav-link" aria-current="page" href={props.link}>{props.text}</a>
        </li>
    )
}

export default NavbarItem;