import React, { FunctionComponent } from "react";

export interface IImageMetadata{
    imagePathFHD: string;
    imagePath4K?: string;
    imagePathMobile?: string;
    altInfo: string;
    title: string;
    description: string;
}

interface IProps{
    images: Array<IImageMetadata>;
}

const Carousel : FunctionComponent<IProps> = (props) => {
    const isMobile = window.innerHeight > window.innerWidth;

    const getImagePath = (image: IImageMetadata) => {
        if (isMobile) {
            return image.imagePathMobile;
        };

        if (window.innerWidth > 1920){
            return image.imagePath4K;
        };

        return image.imagePathFHD;
    };

    return (
        <div id='main-page-carousel' className='carousel slide' data-bs-ride='carousel'>
            <div className='carousel-indicators'>
                {props.images.map((_, index) => (
                    <button 
                        type='button'
                        key={`carousel-button_${index}`}
                        data-bs-target='#main-page-carousel' 
                        data-bs-slide-to={index}
                        className={index === 0 ? 'active' : ''} 
                        aria-current={index === 0 ? 'true' : 'false'}
                        aria-label={`Slide ${index}`}/>
                ))}
            </div>
            <div className='carousel-inner'>
                {props.images.map((image, index) => (
                    <React.Fragment key={index}>
                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                        <img src={getImagePath(image)} className='d-block w-100' alt={image.altInfo}/>
                        <div className='carousel-caption d-md-block'>
                            <h5>{image.title}</h5>
                            <p>{image.description}</p>
                        </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <button className='carousel-control-prev' type='button' data-bs-target='#main-page-carousel' data-bs-slide='prev'>
                <span className='carousel-control-prev-icon' aria-hidden='true'/>
                <span className='visually-hidden'>Predchozi</span>
            </button>
            <button className='carousel-control-next' type='button' data-bs-target='#main-page-carousel' data-bs-slide='next'>
                <span className='carousel-control-next-icon' aria-hidden='true'/>
                <span className='visually-hidden'>Dalsi</span>
            </button>
        </div>
    )
}

export default Carousel;