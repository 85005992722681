import React, { useState } from "react";
import { ITabContent, TabPanel } from "../components/tabPanel/TabPanel";
import { AuthContext } from "../core/AuthContext";
import { AnnoucmentManagement } from "./Annoucment/admin/AnnoucmentManagement";
import { DocumentManagement } from "./Document/admin/DocumentManagement";
import { GalleryManagement } from "./Gallery/admin/GalleryManagement";
import { LinkManagement } from "./Links/admin/LinkManagement";
import { MassesManagement } from "./Masses/admin/MassesManagement";

const Admin : React.FunctionComponent = () => {
    const { logout } = React.useContext(AuthContext);
    const [isHintVisible, setIsHintVisible] = useState(false);
    const tabData : Array<ITabContent> = [
        {
            tabTitle: 'Mše',
            tabKey: 'masses',
            tabContent: <MassesManagement />,
        },
        {
            tabTitle: 'Ohlášky',
            tabKey: 'annoucment',
            tabContent: <AnnoucmentManagement />,
        },
        {
            tabTitle: 'Dokumenty', 
            tabKey: 'documents',
            tabContent: <DocumentManagement />,
        },
        {
            tabTitle: 'Galerie',
            tabKey: 'gallery',
            tabContent: <GalleryManagement />,
        },
        {
            tabTitle: 'Odkazy',
            tabKey: 'links',
            tabContent: <LinkManagement />,
        }
    ];

    return (
        <>
            <h1>Administrace</h1>
            <div className="row">
                <div className="col-12">
                    <button onClick={logout}>Odhlášení</button>
                </div>
            </div>
            <div className="row">
                <span className="link mt-2" onClick={() => setIsHintVisible(!isHintVisible)}>
                    {isHintVisible ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
                </span>
            </div>
            {isHintVisible && (
                <div className="row">
                    <div className="col-12">
                        <h5>Soubory</h5>
                        <p>
                            Přidání nových souborů (fotky, dokumenty) probíhá pomocí služby FirebaseConsole.
                        </p>
                        <p>
                            Přihlaste se do služby <a href='https://console.firebase.google.com/' target='_blank' rel='noreferrer'>Firebase</a> pomocí přihlašovacích údajů stejných jako k tomuto účtu Google.
                        </p>
                        <p>
                            Vstupte do sekce Storage - menu po levé straně obrazovky. Uvidíte strukturu se složkami, kde se nacházejí soubory.
                        </p>
                        <p>
                            Soubory pojmenovávejte tak, jak chcete, aby se jejich název zobrazoval na stránkách (přípony souborů se automaticky odstraní).
                        </p>

                        <h5>Práce s textovými informacemi</h5>
                        <p>
                            Textové informace (Aktuality a ohlášky, Bohoslužby, Odkazy) jsou uloženy v databázi ve formátu JSON.
                            Aktualizace těchto informací bude probíhat přímo v této sekci tak, že nahrajete soubor JSON, čímž
                            nahradíte jeho starší verzi. Příklad tohoto formátu najdete v nápovědě u každé sekce zvlášť.
                            Soubory stáhnete kliknutím na odkaz, následně klikněte pravým tlačítkem myši a zvolte Uložit jako.
                        </p>
                        <p>
                            Soubory typu JSON upravíte v jakémkoliv sofistikovanějším textovém editoru jako například:
                            PSPad, Sublime Text nebo programovacích nástrojích jako VS Code, Visual Studio.
                        </p>
                    </div>
                </div>
            )}
            <TabPanel tabContents={tabData} />
        </>
    );
}

export { Admin };