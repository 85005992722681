const Contact : React.FunctionComponent = () => {
    return (
        <>
        <h3>Kontakty</h3>
        <div className="row">
            <div className="col-sm">
                <iframe title="parish_map" className="map" src="https://en.frame.mapy.cz/s/cokopapage" ></iframe>
            </div>
            <div className="col text-start">
                Římskokatolický farní úřad<br/>
                671 71 HOSTĚRADICE 90<br/>
                Tel.: 515 333 238<br/>
                Email: <a href='mailto:farnost@farnost-hosteradice.cz'>farnost@farnost-hosteradice.cz</a><br/>
                Jihomoravský kraj, okres Znojmo<br/>
                <br/>
                Čísla účtu farností: 
                <br/>1583469349/0800 - Hostěradice
                <br/>1582810349/0800 - Trstěnice
                <br/><br/>
                Do poznámky uvěďte DAR.<br/>
                Všem dárcům velké Pán Bůh zaplať!<br/>
                <br/>
                Správce farnosti:<br/>
                P. Mgr. Josef Dvořák
            </div>
        </div>
        </>
    )
}

export default Contact;