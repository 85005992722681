import { FunctionComponent } from "react";

interface IProps{}

const JindrichInfo : FunctionComponent<IProps> = () => {
    return (
        <>
            <h3>Svatý Jindřich</h3>
            <p className="text-justify">
                Svatý Jindřich byl manželem <a href="/KunhutaInfo">svaté Kunhuty</a>, které je zasvěcen farní kostel v Hostěradicích.
            </p>
            <p className="text-justify">
                Z dějepisu je znám jako Jindřich II. Narodil se roku 973 jako syn bavorského vévody a vnuk Oty Velikého.
                 Vychováván byl pod dohledem řezenského biskupa sv. Wolfganga. Roku 1002 se stal německým králem a roku 1004
                  králem italským. Císařem římským se stal roku 1014 po svém bratranci Otovi III., který zemřel v mladém věku.
                   Zvolen byl ostatními feudály velmi jednotně, protože země byla rozdělena a páni věřili, že Jindřich dokáže udržet moc a pořádek.
            </p>
            <p className="text-justify">
                Přes všechny politické a válečné úspěchy nezpychl a dokázal se vždy poradit s moudrými lidmi své doby.
                 Mezi jeho blízké přátele patřil sv. Odilo, opat kláštera v Cluny. Později Jindřich uvažoval o vstupu do
                  kláštera Saint Vanne ve francouzském Verdunu. Opat kláštera ho však přesvědčil, že jeho poslání je pokračovat v započaté práci.
            </p>
            <p className="text-justify">
                Se svojí manželkou Kunhutou si vzájemně pomáhali v duchovní dokonalosti a snažili se o zajištění co největšího
                 blahobytu pro svoje poddané. Jako vladař Jindřich cítil odpovědnost za víru svých poddaných, a proto podporoval
                  misijní činnosti a reformní hnutí v církvi. Podporoval zakládání klášterů a nových diecézí, s manželkou založili
                   biskupský kostel v Bambergu, kde jsou oba pohřbeni.
            </p>
            <p className="text-justify">
                Svatý Jindřich zemřel v 52 letech roku 1024. Za svatého byl prohlášen roku 1146 a jeho památka připadá na 13. červenec.
            </p>
        </>
    )
}

export default JindrichInfo;