import { FunctionComponent } from "react";
import Carousel from "../components/Carousel";
import { imagePaths } from "../content/images/imagePaths";

const HomeWithCarousel : FunctionComponent = () => {
    const { getHomePagePhotos } = imagePaths();

    return (
        <Carousel images={getHomePagePhotos()}/>
    )
}

export default HomeWithCarousel;