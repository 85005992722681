import { useState } from "react"
import { ILinkSchema, useLinkService } from "../../../dataServices/link/linkService";
import { useFileHelper } from "../../../helpers/fileHelper";
import { JsonSchemaHint } from "../../Shared/JsonSchemaHint";

const LinkManagement : React.FunctionComponent = () => {
    const [model, setModel] = useState<ILinkSchema>();
    const fileHelper = useFileHelper();
    const linkSerice = useLinkService();

    const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        fileHelper.getJsonFile(event, setModel);
    };

    const handleOnUploadLinksClick = async () => {
        if(model && linkSerice.validateLinkJson(model) === true){
            await linkSerice.addLinks(model)
                .then(_ => 
                    {
                        window.alert('Nahrávání dokončeno!')
                        setModel(undefined);
                    });
        }
    };

    return (
        <>
            <h2>Správa odkazů</h2>
            <JsonSchemaHint
                downloadFileName='LinkSchema.json'
                jsonSchemaUrl='https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/JsonSchemaExamples%2FlinkSchema.json?alt=media&token=4b2d59ac-f2db-45bc-bf2b-780509d82ce0'
            />
            <input type='file' onChange={onFileSelected}/>
            <button onClick={handleOnUploadLinksClick} disabled={!model}>Nahrát odkazy ze souboru</button>
        </>
    );
};

export { LinkManagement };