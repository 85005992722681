import { IMass } from "../../dataServices/massDay/massDayService";

const MassPlace : React.FunctionComponent<IMass> = (props) => {
    return (
        <div className="row">
            <div className="col-6"/>
            <div className="col-4">{props.Place}</div>
            <div className="col-2 text-right">{props.Time}</div>
        </div>
    );
};

export { MassPlace };