import { useEffect, useState } from "react";
import { IOrderable } from "../../core/interfaces/IOrderable";

enum Direction {
    Up,
    Down,
};

interface IProps {
    data: Array<IOrderable>;
    onSubmit: (model: Array<any>) => Promise<void>;
};

const ItemSort: React.FunctionComponent<IProps> = (props) => {
    const [data, setData] = useState<Array<IOrderable>>(props.data);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const reorder = (direction: Direction, index: number) => {
        const draft = data.map(item => ({...item}));
        draft[index] = data[direction === Direction.Down ? index + 1 : index - 1];
        draft[direction === Direction.Down ? index + 1 : index - 1] = data[index];
        draft.forEach((item, index) => item.Index = index);

        setData(draft);
    };

    const onSubmit = async (data: Array<IOrderable>) => {
        setIsSubmitting(true);
        await props.onSubmit(data)
            .then(_ => 
                {
                setIsSubmitting(false);
                window.alert('Uložení proběhlo úspěšně!')
            });
    };

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    return (
        <div className="mt-3">
            <h3>Řazení položek</h3>
            <ul>
                {data.sort((a, b) => a.Index - b.Index).map((item, index) => (
                    <li key={index}>
                        {item.Label}
                        <button 
                            disabled={item.Index === 0} 
                            type='button' 
                            className="btn btn-light m-1" 
                            onClick={() => reorder(Direction.Up, item.Index)}>^</button>
                        <button 
                            disabled={item.Index === data.length - 1} 
                            type='button' 
                            className="btn btn-light" 
                            onClick={() => reorder(Direction.Down, item.Index)}>v</button>
                    </li>
                ))}
            </ul>
            <button disabled={isSubmitting} className="btn btn-primary" onClick={() => onSubmit(data)}>Uložit</button>
        </div>
    );
};

export { ItemSort };