import { GoogleAuthProvider, signInWithRedirect } from "@firebase/auth";
import React, { useEffect, useState } from "react";
import { getAuthorization } from "../firebase";

interface IAuthContextProps {
    isAdminLoggedIn?: boolean;
    isAuthenticating?: boolean;
    login?: () => void;
    logout?: () => void;
}

const AuthContext = React.createContext<IAuthContextProps>({});

const AuthContextProvider : React.FunctionComponent = (props) => {
    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const firebaseAuth = getAuthorization();

    useEffect(() => {
        const authValue = localStorage.getItem('IsAuthenticating') === 'true';
        setIsAuthenticating(authValue);
    }, [isAdminLoggedIn, isAuthenticating]);

    firebaseAuth.onAuthStateChanged(user => {
        if (user){
            localStorage.removeItem('IsAuthenticating');
            setIsAdminLoggedIn(isAdminCredentials(user.uid));
        }});

    const isAdminCredentials = (userUid: string) : boolean => {
        return userUid !== undefined && userUid === process.env.REACT_APP_AUTH_UID;
    }

    const login = async () => {
        const provider = new GoogleAuthProvider();
        localStorage.setItem('IsAuthenticating', 'true');
        await signInWithRedirect(firebaseAuth, provider,);
    };
    
    const logout = async () => {
        firebaseAuth.signOut().then(_ => {
            setIsAdminLoggedIn(false);
        });
    };

    const values : IAuthContextProps = { isAdminLoggedIn, isAuthenticating, login, logout };

    return <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>
};

export { AuthContext, AuthContextProvider }