import React from "react";
import { FileType } from "../enums/enums";


const useFileHelper = () => {
    const getJsonFile = <TModel>(e: React.ChangeEvent<HTMLInputElement>, callBack: (model: TModel) => void) => {
        const file = getFile(e, FileType.Json);
        readFile<TModel>(file!, callBack);
    };

    const getFile = (e: React.ChangeEvent<HTMLInputElement>, type: FileType) : File | undefined => {
        const file = e.target.files?.item(0);
        if (!file || !validateFileType(file, type)){
            e.target.value = '';
            alert('Soubor není ve správném formátu nebo se nenačetl správně. Opakute prosím akci znovu.');
        }else {
            return file;
        }
    };

    const validateFileType = (file: File, type: FileType) => {
        return file.type === type;
    };

    const readFile = <TModel>(file: File, onReadCallback: (model: TModel) => void) => {
        const fileReader = new FileReader();
        fileReader.onload = (e: ProgressEvent<FileReader>) => {
            const result = e.target?.result;
            if(result){
                const parsedJson = JSON.parse(result.toString()) as TModel;
                onReadCallback(parsedJson);
            };
        };
        fileReader.readAsText(file!);
    };

    const removeFileNameExtension = (fileName: string) : string => {
        return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    };

    const replaceUnderscoresWithWhiteSpace = (fileName: string) : string => {
        return fileName.replace('_', ' ');
    };

    const getFileLabel = (fileName: string) : string => {
        const withoutExtension = removeFileNameExtension(fileName);
        const result = replaceUnderscoresWithWhiteSpace(withoutExtension);
        return result;
    }

    return { getJsonFile, getFileLabel };
};

export { useFileHelper };