import Link from "../../components/Link";
import { DataFetcher } from "../../dataServices/core/DataFetcher";
import { ILink, useLinkService } from "../../dataServices/link/linkService";

const Links : React.FunctionComponent = () => {
    const linkDataService = useLinkService();

    return (
        <>
        <h3>Odkazy</h3>
        <DataFetcher dataMethod={linkDataService.getLinks}>
            {(data: Array<ILink>) => (
                <div className="list-group">
                    {data.map((link, index) => (
                        <Link key={index} name={link.Name} url={link.Url}/>
                    ))}
                </div>
            )}
        </DataFetcher>
      </>
    )
}

export default Links;