import React from "react";
import { useMassDayService } from "../../../dataServices/massDay/massDayService";
import { JsonSchemaHint } from "../../Shared/JsonSchemaHint";
import { FileInput } from "../../../components/FileInput";

const MassesManagement : React.FunctionComponent = () => {
    const massDayService = useMassDayService();

    return (
        <>
            <h2>Správa plánu mší</h2>
            <JsonSchemaHint
                downloadFileName='MassesSchema.json'
                jsonSchemaUrl='https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/JsonSchemaExamples%2FmassDaySchema.json?alt=media&token=526cd6f1-5540-4567-969b-a176e06e4943'
            />
            <FileInput 
                buttonLabel="Nahrát plán mší ze souboru" 
                validateFile={massDayService.validateMassDayJson}
                onUpload={massDayService.addMassDays}
                />
        </>
    );
};

export { MassesManagement };