import { FunctionComponent } from "react";
import AboutParishDropdown from "./AboutParishDropdown";
import NavbarItem from "./NavbarItem";

interface IProps{}

const Navbar: FunctionComponent<IProps> = () => {
    return (
      <nav className="navbar navbar-expand-md navbar-light navbar-bg fixed-top">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#NavbarContainer" aria-controls="NavbarContainer" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>
          <div className="collapse navbar-collapse" id="NavbarContainer">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{marginLeft: '10px'}}>
              <NavbarItem link="/" text="Domů" />
              <NavbarItem link="/Annoucments" text="Aktuality" />
              <NavbarItem link="/Masses" text="Bohoslužby" />
              <AboutParishDropdown/>
              <NavbarItem link="/Documents" text="Dokumenty"/>
              <NavbarItem link="/Gallery" text="Fotogalerie"/>
              <NavbarItem link="/Contacts" text="Kontakty"/>
              <NavbarItem link="/Links" text="Odkazy"/>
            </ul>
          </div>
        </div>
    </nav>
  )
}

export default Navbar;