import { FunctionComponent } from "react";

interface IProps{}

const MisoviceChapel : FunctionComponent<IProps> = () => {
    return (
        <>
            <h3>Kaple Míšovice</h3>
            <p className="text-justify">
                Ve farnosti je více sakrálních objektů. Mezi nejvýznamnější patří kaple v Míšovicích (část Hostěradic) a&nbsp;
                <a href="/SkaliceChapel">kaple ve vedlejší obci Skalice.</a>
            </p>
            <p className="text-justify">
                Kaple v Míšovicích je zasvěcena Nanebevzetí Panny Marie. Bohoslužby jsou zde nepravidelně, podle ohlášení.
                 Poutní mše svatá se zde koná v neděli kolem 15. srpna, kdy se slaví slavnost Nanebevzetí Panny Marie.
            </p>
            <div className="row">
                <div className="col-lg-2 col-sm-1"/>
                <div className="col">
                    <img src='https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fhomepage%2FKapleMisoviceFHD.jpg?alt=media&token=ccae7645-0cf4-402a-b533-174b6a41c737' 
                    alt='Kaple Míšovice'
                    className='d-block w-100'
                    style={{border: '1px solid rgb(150, 150, 150)'}}/>
                </div>
                <div className="col-lg-2 col-sm-1"/>
            </div>
        </>
    )
}

export default MisoviceChapel;