import { child, get, set } from "@firebase/database";
import { getDatabaseReference } from "../../firebase";
import { urls } from "../../urls";

export interface ILinkSchema{
    Link: Array<ILink>;
}

export interface ILink{
    Name: string;
    Url: string;
};

const useLinkService = () => {
    const dbRef = getDatabaseReference(urls.Link.Index);

    const addLinks = async(model: ILinkSchema) => {
        await set(dbRef, model.Link);
    }

    const getLinks = async() : Promise<Array<ILink>> => {
        const result = await get(child(dbRef, '/'));
        return result.val();
    };

    const validateLinkJson = (model: ILinkSchema) => {
        try{
            model.Link.forEach(link => {
                if(link.Name === undefined || link.Url === undefined){
                    throw new Error("Chybí některá z požadovaných hodnot");
                };
            });
            return true;
        } catch(e: any){
            window.alert("Soubor JSON není ve správném formátu.\n" +
                "Stáhněte si ukázkový JSON soubor z odkazu v  nápovědě.\n" +
                `Chybová hláška: ${e.message}`);
            return false;
        }
    }

    return { addLinks, getLinks, validateLinkJson };
};

export { useLinkService };