import { IMassDay } from "../../dataServices/massDay/massDayService";
import { MassPlace } from "./MassPlace";

const MassDay : React.FunctionComponent<IMassDay> = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div className="strong">{props.Day}</div>
                    {props.Description}
                </div>
                <div className="col-6"/>
            </div>
            {props.Masses && props.Masses.map((mass, index) => <MassPlace key={index} {...mass}/>)}
            <div className="line-breaker"/>
        </>
    );
};

export { MassDay };