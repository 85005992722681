import { useState } from "react";
import { useFileHelper } from "../helpers/fileHelper";
import { v4 as uuid } from "uuid";

interface IProps{
    buttonLabel: string;
    validateFile: (model: any) => boolean;
    onUpload: (model: any) => Promise<void>;
};

const FileInput = (props: IProps) => {
    const [model, setModel] = useState<any>();
    const fileHelper = useFileHelper();
    const [fileInputKey, setFileInputKey] = useState(uuid());

    const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        fileHelper.getJsonFile(event, setModel);
    };

    const onUploadClick = async () => {
        if(model && props.validateFile(model) === true){
            await props.onUpload(model);
            window.alert('Nahrávání dokončeno!')
            setModel(undefined);
            setFileInputKey(uuid());
        }
    };

    return (
        <>
            <input type='file' onChange={onFileSelected} key={fileInputKey}/>
            <button onClick={onUploadClick} disabled={!model}>{props.buttonLabel}</button>
        </>
    );
};

export { FileInput };