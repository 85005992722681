import { FunctionComponent } from "react";

interface IProps{}

const AboutParishDropdown: FunctionComponent<IProps> = () => {
    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="AboutParishDropdown"  data-bs-toggle="dropdown" aria-expanded="false" href="/ActualInfo">O farnosti</a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                    <a className="dropdown-item" href="/History">Historie</a>
                </li>
                <li>
                    <a className="dropdown-item" href="/KunhutaInfo">Sv. Kunhuta</a>
                </li>
                <li>
                    <a className="dropdown-item" href="/JindrichInfo">Sv. Jindřich</a>
                </li>
                <li>
                    <a className="dropdown-item" href="/MariaPillar">Mariánský sloup</a>
                </li>
                <li>
                    <a className="dropdown-item" href="/SkaliceChapel">Kaple Skalice</a>
                </li>
                <li>
                    <a className="dropdown-item" href="/MisoviceChapel">Kaple Míšovice</a>
                </li>
            </ul>
        </li>
    )
}

export default AboutParishDropdown;