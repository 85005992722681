import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Annoucments from './pages/Annoucment/Announcments';
import Navbar from './components/navbar/Navbar';
import JindrichInfo from './pages/JindrichInfo';
import KunhutaInfo from './pages/KunhutaInfo';
import MariaPillar from './pages/MariaPillar';
import MisoviceChapel from './pages/MisoviceChapel';
import ParishHistory from './pages/ParishHistory';
import SkaliceChapel from './pages/SkaliceChapel';
import PageWrapper from './components/PageWrapper';
import HomeWithCarousel from './pages/HomeWithCarousel';
import Masses from './pages/Masses/Masses';
import Links from './pages/Links/Links';
import Contact from './pages/Contact';
import Documents from './pages/Document/Documents';
import Gallery from './pages/Gallery/Gallery';
import { GalleryDetail } from './pages/Gallery/GalleryDetail';
import { Login } from './pages/Login';
import PrivateRoute from './core/PrivateRoute';
import { Admin } from './pages/Admin';
import { AuthContextProvider } from './core/AuthContext';

function App() {
  return (
    <BrowserRouter>
      <Navbar></Navbar>
      <Switch>
        <Route path="/" exact component={HomeWithCarousel}/>
        <PageWrapper>
            <Route path="/Annoucments" exact={true} component={Annoucments}/>
            <Route path="/History" component={ParishHistory}/>
            <Route path="/KunhutaInfo" component={KunhutaInfo}/>
            <Route path="/JindrichInfo" component={JindrichInfo}/>
            <Route path="/MariaPillar" component={MariaPillar}/>
            <Route path="/SkaliceChapel" component={SkaliceChapel}/>
            <Route path="/MisoviceChapel" component={MisoviceChapel}/>
            <Route path="/Masses" component={Masses}/>
            <Route path="/Contacts" exact={true} component={Contact}/>
            <Route path="/Links" component={Links}/>
            <Route path="/Documents" component={Documents}/>
            <Route path="/Gallery" component={Gallery} exact/>
            <Route path="/Gallery/:id" component={GalleryDetail} exact/>
            <AuthContextProvider>
              <PrivateRoute path='/Admin' component={Admin} />
              <Route path="/Login" component={Login}/>
            </AuthContextProvider>
        </PageWrapper>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
