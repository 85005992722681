import * as firebase from '@firebase/app';
import { Auth, getAuth } from '@firebase/auth';
import * as firebaseStorage from "@firebase/storage";
import * as firebaseDatabase from "@firebase/database";
import * as firebaseFunctions from "@firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID 
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

const getStorage = (url?: string) : firebaseStorage.StorageReference => {
    const storage = firebaseStorage.getStorage(firebaseApp);
    const storageRef = firebaseStorage.ref(storage, url);
    return storageRef;
}

const getFileUrl = async (url: string) : Promise<string> => {
  const storageRef = getStorage(url);
  return await firebaseStorage.getDownloadURL(storageRef);
}

const getAuthorization = () : Auth => {
  return getAuth(firebaseApp);
}

const getDatabaseReference = (url?: string) : firebaseDatabase.DatabaseReference => {
  const db = firebaseDatabase.getDatabase(firebaseApp);
  const dbReference = firebaseDatabase.ref(db, url);
  return dbReference;
}

const getFunction = <Request, Response>(functionName: string) : firebaseFunctions.HttpsCallable<Request, Response> => {
  const functions = firebaseFunctions.getFunctions(firebaseApp);
  // firebaseFunctions.connectFunctionsEmulator(functions, 'localhost', 5001);
  return firebaseFunctions.httpsCallable<Request, Response>(functions, functionName);
}

export { getAuthorization, getStorage, getFileUrl, getDatabaseReference, getFunction };