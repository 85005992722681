import React, { PropsWithChildren, ReactNode, useEffect, useState } from "react";

interface IProps {
    triggerFetchProperty?: any;
    dataMethod: () => Promise<any> | any;
    children: (data: any) => ReactNode;
};

const DataFetcher : React.FunctionComponent<PropsWithChildren<IProps>> = (props) => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const getData = async () => {
        const result = await props.dataMethod();
        if (result){
            setData(result);
            setIsLoading(false);
        };
    };

    useEffect(() => {
        getData();
    }, [props.triggerFetchProperty]);

    return (
        <>
            {isLoading ? <React.Fragment/> : props.children(data)}
        </>
    );
};

export { DataFetcher };