import { FunctionComponent } from "react";

interface IProps{}

const KunhutaInfo : FunctionComponent<IProps> = () => {
    return (
        <>
            <h3>Svatá Kunhuta</h3>
            <p className="text-justify">
                Svatá Kunhuta se narodila kolem roku 978 lucemburskému hraběti Sigfriedovi a Hedvice Německé.
                 Po dosažení dospělosti si ji vzal za manželku mladý Jindřich Bavorský, který byl po smrti Oty III.
                  zvolen v Mohuči německým císařem a později byl v Pavii korunován též italským králem. Papež Benedikt VIII.
                   ho roku 1014 ještě v Římě korunoval za císaře tehdejší Svaté říše římské.
            </p>
            <p className="text-justify">
                Kunhuta našla v manželovi člověka, který se jí podobal v jejich ctnostech a také z Jindřicha vyrostl světec,
                 který byl později prohlášen za svatého. Oba manželé v období své vlády konali mnoho dobra a vláda Jindřicha II.
                  se stala řadou dobrých skutků, obdobím svornosti a spravedlnosti.
            </p>
            <p className="text-justify">
                Manželství svaté Kunhuty bylo bezdětné, proto s manželem uzavřeli slib čistoty a svoje manželství vedli
                 především v duchovním rozměru. Své životy vedli ke křesťanské dokonalosti.
            </p>
            <p className="text-justify">
                Bohužel ani toto manželství nebylo uchráněno od zkoušek. Po císařském dvoře se začala šířit pomluva,
                 že Kunhuta byla svému muži nevěrná. Intenzita pomluv byla natolik silná, že jí po nějakém čase začal věřit i sám císař.
                  Císařovna se proto musela podrobit tehdy používanému „Božímu soudu“. Podstatou byla víra, že Bůh nenechá trpět
                   nikoho nevinného a jeho nevinu ukáže i zázrakem. Jak vypráví Kunhutini životopisci, byla císařovna nucena kráčet
                    po rozpálených kusech železa. Ve zkoušce obstála a císař ji veřejně odprosil.
            </p>
            <p className="text-justify">
                Jindřich zemřel roku 1024. Kunhuta se vzdala světské slávy a odešla do ženského kláštera v Kaufungen,
                 který sama založila. Tam během bohoslužby odevzdala císařskou korunu, královský šat i ostatní znaky císařské hodnosti.
                  Nechala si ostříhat vlasy a zůstala v tomto benediktinském klášteře. Zde v pokoře žila až do své smrti (1033 nebo 1039).
                   Při slavném pohřbu byla uložena vedle svého manžela v císařské hrobce v bamberské katedrále.
            </p>
            <p className="text-justify">
                Památka svaté Kunhuty připadá na 3. března.
            </p>
        </>
    )
}

export default KunhutaInfo;