import { getDownloadURL, listAll } from "@firebase/storage";
import { push, child, get, DataSnapshot } from "@firebase/database";
import { urls } from "../../../urls";
import { IGalleryFolder, IGalleryFolderInfo, IGalleryFolderItem, useGalleryService } from "../../../dataServices/gallery/galleryService";
import { getDatabaseReference, getStorage } from "../../../firebase";
import { useFileHelper } from "../../../helpers/fileHelper";

const useGallery = () => {
    const galleryDataService = useGalleryService();
    const { getFileLabel } = useFileHelper();
    
    const getStorageFolderNames = async () : Promise<Array<string>> => {
        const storage = getStorage(urls.Gallery.Index);
        const result = await listAll(storage);
        return result.prefixes.map(p => p.name);
    };

    const getDatabaseFolderNames = async () : Promise<Array<string>> => {
        const folderInfo = await galleryDataService.getFolderInfo();
        return folderInfo.map(f => f.Label);
    }

    const getDatabaseGalleryData = async () : Promise<DataSnapshot> => {
        const dbRef = getDatabaseReference(urls.Gallery.FolderDetail);
        return await get(child(dbRef, '/'));
    }

    const regenerateWholeGallery = async () : Promise<void> => {
        const storageFolderNames = await getStorageFolderNames();
        const databaseFolderNames = await getDatabaseFolderNames();
        await actualizeGallery(storageFolderNames, databaseFolderNames ?? []);
    }

    const actualizeGallery = async (storageFolderNames: string[], dbFolderNames: string[]) : Promise<void> => {
        const folderDetailResult: Array<IGalleryFolder> = [];
        await Promise.all(storageFolderNames.map(async (storageFolderName) => {
            if (!dbFolderNames.includes(storageFolderName)){
                folderDetailResult.push(await getFolderObject(storageFolderName));
            }
        }));

        await Promise.all(folderDetailResult.map(async (folderDetail, index) => {
            const saveResult = await push(getDatabaseReference(urls.Gallery.FolderDetail), folderDetail);
            const folderInfoModel : IGalleryFolderInfo = {
                DateCreated: new Date().toDateString(),
                Label: folderDetail.FolderName,
                Id: saveResult.key ?? '',
                Index: index,
            };
            await push(getDatabaseReference(urls.Gallery.FolderInfo), folderInfoModel);
        }));
    }

    const getFolderObject = async (folderName: string): Promise<IGalleryFolder> => {
        const folderItems: Array<IGalleryFolderItem> = [];
        const storage = getStorage(`${urls.Gallery.Index}/${folderName}`);
        const folderContent = await listAll(storage);
        await Promise.all(folderContent.items.map(async (image) => {
                folderItems.push(
                    {
                        Name: getFileLabel(image.name),
                        Url: await getDownloadURL(image),
                    }
                );
            }));

        return { 
            FolderName: folderName,
            Items: folderItems
        };
    }
 
    return { regenerateWholeGallery, getDatabaseGalleryData };
};

export {useGallery};