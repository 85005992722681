import { child, get, set } from "@firebase/database";
import { getDatabaseReference } from "../../firebase";
import { urls } from "../../urls";

export interface IMassDaySchema {
    MassDay: Array<IMassDay>;
};

export interface IMass{
    Place: string;
    Time: string;
}

export interface IMassDay
{
    MassDayId: number;
    Day: string;
    Description: string;
    Masses: Array<IMass>;
}

const useMassDayService = () => {
    const dbRef = getDatabaseReference(urls.MassDay.Index);

    const getMassDays = async() : Promise<Array<IMassDay>> => {
        const snapshot = await get(child(dbRef, '/'));
        const result = await snapshot.val() as Array<IMassDay>;
        result.sort((a, b) => a.MassDayId - b.MassDayId);
        return result;
    };

    const addMassDays = async(model: IMassDaySchema): Promise<void> => {
        await set(dbRef, model.MassDay);
    };

    const validateMassDayJson = (model: IMassDaySchema) : boolean => {
        try {
            model.MassDay.forEach(massDay => {
                if (massDay.Day === undefined || massDay.MassDayId === undefined || massDay.Description === undefined) {
                    throw new Error("Chybí některá z požadovaných hodnot");
                };
                massDay.Masses.forEach(mass => {
                    if (mass.Place === undefined || mass.Time === undefined) {
                        throw new Error("Chybí některá z požadovaných hodnot");
                    }
                });
            });
            return true;
        } catch(e: any) {
            window.alert("Soubor JSON není ve správném formátu.\n" +
                "Stáhněte si ukázkový JSON soubor z odkazu v  nápovědě.\n" +
                `Chybová hláška: ${e.message}`);
            return false;
        }
    }

    return { getMassDays, addMassDays, validateMassDayJson };
};

export { useMassDayService };