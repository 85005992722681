import { useState } from "react"

interface IProps{
    jsonSchemaUrl: string;
    downloadFileName: string;
}

const JsonSchemaHint : React.FunctionComponent<IProps> = (props) => {
    const [isHintVisible, setIsHintVisible] = useState(false);

    return (
        <>
            <div className="row">
                <span className="link mb-2" onClick={() => setIsHintVisible(!isHintVisible)}>
                    {isHintVisible ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
                </span>
            </div>
                {isHintVisible && (
                    <div className="row">
                        <div className="col-12">
                            <p>
                                Příklad formátu JSON souboru můžete stáhnout&nbsp;
                                <a 
                                    href={props.jsonSchemaUrl}
                                    download={props.downloadFileName}
                                    target='_blank'
                                    rel='noreferrer'>zde</a>.
                            </p>
                        </div>
                    </div>
                )}
        </>
    );
};

export { JsonSchemaHint };