import Link from "../../components/Link";
import { DataFetcher } from "../../dataServices/core/DataFetcher";
import { useGalleryService, IGalleryFolderInfo } from "../../dataServices/gallery/galleryService";

const Gallery : React.FunctionComponent = () => {
    const gallery = useGalleryService();

    return (
        <>
            <h3>Galerie</h3>
            <DataFetcher dataMethod={gallery.getFolderInfo}>
                {(data: Array<IGalleryFolderInfo>) => (
                    <div className='row'>
                        {data.length > 0 && data.map((folder, index) => (
                            <Link key={index} name={folder.Label} url={`/Gallery/${folder.Id}`} openInNewTab={false}/>
                        ))}
                    </div>
                )}
            </DataFetcher>
        </>
    );
}
 
export default Gallery;